import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { Row, Col, Button } from "reactstrap";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import "../DashboardPages.css";
import "./FlashDealStyles.css";
import bids from "../../../assets/images/get-bids.png";
import CurrentDeals from "./CurrentDeals";
import PendingDeals from "./PendingDeals";
import ExpiredOrPaused from "./ExpiredOrPaused";
import { getAllDeals } from "../../../components/Header/Data2";
import { FadeLoader } from "react-spinners"; // Import FadeLoader
const FlashDeals = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allDeals, setAllDeals] = useState([]);
  const navigate = useNavigate();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [activeTab, setActiveTab] = useState("active");
  //dealStatus = 0 pending
  //dealStatus = 1 Active active
  //dealStatus = 2 deleted
  //dealStatus = 3 paused
  //dealStatus = 4 expired
  let pendingDeals = allDeals.filter((deal) => deal.dealStatus === 0);
  let activeDeals = allDeals.filter((deal) => deal.dealStatus === 1);
  let expiredOrPaused = allDeals.filter(
    (deal) => deal.dealStatus === 3 || deal.dealStatus === 4
  );
  console.log(pendingDeals, "pendingDeals");
  const fetchData = async () => {
    setIsLoading(true);
    let formData = null;
    if (user.RoleId === 1) {
      formData = { roleId: user.RoleId };
    } else {
      formData = { createdBy: user.UserID };
    }
    const deals = await getAllDeals(formData);
    setAllDeals(deals);
    setIsLoading(false);
  };
  useEffect(() => {
    console.log(user, "user");
    fetchData();
  }, []);

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="flashDeal-container position-relative">
            {isLoading && (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={isLoading}
                className="position-absolute top-50 start-50 translate-middle"
              />
            )}
            {2 > 0 ? (
              <div>
              <div className="row ">
                <div className="text-end">
                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      navigate("/flash-deals/new-deal");
                    }}
                  >
                    <BiPlusCircle /> Add New
                  </Button>{" "}
                </div>
                </div>
                <div className="d-flex row mt-3 px-2 wc_ib-tabs ">
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "active" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("active")}
                    style={{
                      textDecoration: activeTab !== "active" && "none",
                      cursor: "pointer",
                    }}
                  >
                    Active
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "expired/Paused" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("expired/Paused")}
                    style={{
                      textDecoration: activeTab !== "expired/Paused" && "none",
                      cursor: "pointer",
                    }}
                  >
                    Expired/Paused
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "pending" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("pending")}
                    style={{
                      textDecoration: activeTab !== "pending" && "none",
                      cursor: "pointer",
                    }}
                  >
                    Pending
                  </h5>
                </div>
                <div className="tab-content">
                  {activeTab === "active" && (
                    <CurrentDeals
                      currentDeals={activeDeals}
                      fetchData={fetchData}
                      isLoading={isLoading}
                    />
                  )}
                  {activeTab === "expired/Paused" && (
                    <ExpiredOrPaused
                      expiredOrPaused={expiredOrPaused}
                      fetchData={fetchData}
                      isLoading={isLoading}
                    />
                  )}
                  {activeTab === "pending" && (
                    <PendingDeals
                      pendingDeals={pendingDeals}
                      fetchData={fetchData}
                      isLoading={isLoading}
                    />
                  )}
                </div>
              </div>
            ) : (
              !isLoading && (
              <div className="ads">
                <img
                  src={bids}
                  alt="img"
                  style={{ width: "10rem", marginBottom: "20px" }}
                />
                <h4>Do you have a specific Need/Request?</h4>
                <p>
                  Post an Ad of your need & reach the local Sikh Community
                </p>
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    navigate("/needs-requests/request");
                  }}
                >
                  <BiPlusCircle /> Post Your Ad
                </Button>
              </div>
              )
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default FlashDeals;
