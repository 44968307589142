import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./RecentAds.css"; // Import or create a CSS file for styling
import { Row, Input, Col, Button } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaCaretDown,
  FaSlidersH,
} from "react-icons/fa";
import { fetchGurdwaras, fetchAllUsers, fetchAllProducts } from "../Data";
import { FadeLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { toast } from "react-toastify";
import LcaCards from "./LcaCards";
import LcaEngagedCouple from "./LcaEngagedCoupleCards";
import { all } from "axios";
import {
  setSelectedSubCategory,
  setSelectedItem,
  setFinalProducts,
  setFinalBwv,
  setActiveTab,
} from "../../../redux/slices/LcaSlice";
import { RiArrowDownDoubleFill } from "react-icons/ri";
import LcaAndBwv from "./LcaAndBwv";
const RecentAds = ({
  handleClear,
  productSubCat,
  isLoading,
  scrollPopupVisible,
  setScrollPopupVisible,
}) => {
  const dispatch = useDispatch();
  const { selectedSubCategory, finalProducts, Lca, activeTab, finalBwv } =
    useSelector((state) => state.lcAds);
  const islogin = useSelector((state) => state.user.isLogin);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("Newer to Older"); // Add state for sorting
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showGurudwaraList, setShowGurudwaraList] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [gurdwaras, setGurdwaras] = useState([]);
  const [engagedCouple, setEngagedCouple] = useState([]);
  const [dropdownAds, setDropdownAds] = useState(false);
  const toggle = () => setDropdownAds((prevState) => !prevState);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(); // Ref for the popup
  // const [scrollPopupVisible, setScrollPopupVisible] = useState(false);
  const scrollPopupRef = useRef();
  const handleSortBy = (option) => {
    setSortBy(option);
    let sortedLca = [...finalProducts]; // Make a copy of the original array
    let sortedBwv = [...finalBwv];

    switch (option) {
      case "Newer to Older":
        sortedLca.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        sortedBwv.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "Older to Newer":
        sortedLca.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        sortedBwv.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        break;
    }
    dispatch(setFinalProducts(sortedLca));
    dispatch(setFinalBwv(sortedBwv));
  };
  const handleSubCategoryClick = (category) => {
    handleItemClick();
    if (Lca) {
      if (selectedSubCategory && selectedSubCategory.id === category.id) {
        dispatch(setSelectedSubCategory(null));
        dispatch(setActiveTab("Customers"));
      } else {
        dispatch(setSelectedSubCategory(category));
      }
      dispatch(setSelectedItem(null));
    }
  };
  const handleItemClick = () => {
    window.scrollTo(0, 370);
    setScrollPopupVisible(true);
  };

  const fetchData = async () => {
    const gurudwaras = await fetchGurdwaras();
    const fetchUsers = await fetchAllUsers();
    const filterUsers = fetchUsers.filter((user) => user.EngagedCoupled === 1);
    setEngagedCouple(filterUsers);
    setGurdwaras(gurudwaras);
  };
  useEffect(() => {
    fetchData();
  }, [islogin]);
  const tgl_filter = () => {
    var myele = document.getElementById("lca-sidebar");
    myele.classList.remove("tgl-close");
    myele.classList.add("tgl-show");
  };
  const handleGurudwaraSelect = (gurudwara) => {
    navigate("/dashboard", { state: { gurudwara } });
    setShowGurudwaraList(false);
  };

  const customOrderMap = {
    "Job Market": 1,
    "Real Estate": 2,
    "Child Care Services": 3,
    "Upcoming Wedding/Event": 4,
    "Local Business Ads": 5,
    "Other Local Ads": 6,
    "Matrimonial/Dating": 7,
  };
  const sortedProductSubCat = [...productSubCat].sort((a, b) => {
    return (
      (customOrderMap[a.Name] || Infinity) -
      (customOrderMap[b.Name] || Infinity)
    );
  });
  const displayNameMap = {
    "Child Care Services": "Child Care",
    "Upcoming Wedding/Event": "Weddings",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        scrollPopupRef.current &&
        !scrollPopupRef.current.contains(event.target)
      ) {
        setScrollPopupVisible(false);
        //window.scroll(0, 0);
      }
    };
    if (scrollPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scrollPopupVisible]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  const screenWidth = window.innerWidth;
  const iPadView = screenWidth > 1300;
  const Popup = ({ closePopup }) => (
    <div className="text-center wc_show_cat_popup d-lg-block d-xl-none d-block">
      <div
        className=""
        ref={popupRef}
        style={{
          // position: "absolute",
          // top: iPadView ? "15%" : "7%",
          // left: iPadView ? "20%" : "50%",
          // transform: "translate(-50%, -50%)",
          padding: "10px",
          backgroundColor: "rgb(246 225 107)",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 5,
          width: 200,
          margin: "-74px auto 10px",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textWrap: "nowrap",
            position: "relative",
            marginBottom: "15px",
          }}
        >
          Select an AD type to view
        </div>
        <div
          style={{
            marginTop: "-17px",
            textAlign: "center",
            marginBottom: "-9px",
          }}
          onClick={closePopup}
        >
          <RiArrowDownDoubleFill size={30} />
        </div>
      </div>
    </div>
  );
  const ScrollPopup = ({ closePopup }) => (
    <div
      ref={scrollPopupRef}
      style={{
        position: "fixed",
        bottom: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "10px 20px",
        backgroundColor: "rgb(246 225 107)",
        animation: "flashingRed 2s infinite",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 9,
        display: scrollPopupVisible ? "block" : "none",
      }}
      onClick={() => {
        closePopup();
        //window.scroll(0, 0);
      }}
    >
      <div style={{ fontSize: "16px", fontWeight: "bold", textWrap: "nowrap" }}>
        Scroll &nbsp;
        <span className="text-primary">
          UP <FaAngleDoubleUp />
        </span>
        &nbsp; / &nbsp;
        <span className="text-primary">
          DOWN <FaAngleDoubleDown />{" "}
        </span>
        to see new ads
      </div>
    </div>
  );
  return (
    <div className="recent-ads-container">
      {scrollPopupVisible && (
        <ScrollPopup closePopup={() => setScrollPopupVisible(false)} />
      )}
      <div>
        {!isLoading && showPopup && (
          <Popup closePopup={() => setShowPopup(false)} />
        )}
        {!isLoading && (
          <div
            style={{
              display: "flex",
              gap: "4.8px",
              width: "100%",
              textWrap: "nowrap",
              overflowX: "scroll",
              overflowY: "hidden",
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              animation: "flashingRed 2s infinite",
              borderRadius: "4px",
            }}
            className="inter-font d-lg-block d-xl-none"
          >
            {sortedProductSubCat.map((item) => (
              <p
                key={item?.id}
                className="subCatList"
                style={{
                  padding: "5px",
                  backgroundColor:
                    selectedSubCategory && selectedSubCategory.id === item.id
                      ? "rgb(186 181 181)"
                      : "rgb(245,245,245)",
                  marginBottom: 0,
                  color: "black",
                  border: "1px solid gray",
                  // marginInlineEnd: "4.7px",
                  // display: "flex",
                  // gap: "2",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
                onClick={() => {
                  handleSubCategoryClick(item);
                }}
              >
                {displayNameMap[item?.Name] || item?.Name}
              </p>
            ))}
          </div>
        )}
      </div>
      <Row className="rounded d-lg-block d-xl-none d-md-block position-relative">
        <div>
          <span
            style={{
              backgroundColor: "#FCEFEF",
              borderRadius: "10px",
              padding: "8px 20px",
              cursor: "pointer",
              display: "inline-block",
              position: "absolute",
              top: "22px",
            }}
            onClick={tgl_filter}
            className="mb-filter-btn"
            id="mb-filter-btn"
          >
            <FaSlidersH /> Filters
          </span>
        </div>
      </Row>
      {/* <Row className="px-3">
        <div>
          <span
            onClick={tgl_filter}
            className="mb-filter-btn"
            id="mb-filter-btn"
          >
            <FaSlidersH /> Filters
          </span>
        </div>
      </Row> */}
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="p-3 text-nowrap"
        id="filters"
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            flexBasis: "33%",
          }}
          id="finalProducts"
        >
          <span className="wd_filter-countaction inter-font d-none d-md-none d-lg-none d-xl-block">
            {(activeTab === "Customers" &&
              finalProducts &&
              finalProducts.length) ||
              (activeTab === "Vendor" && finalBwv && finalBwv.length) ||
              "0"}{" "}
            results
          </span>

          <div
            onClick={handleClear}
            style={{
              backgroundColor: "#FCEFEF",
              borderRadius: "10px",
              padding: "5px 10px",
              cursor: "pointer",
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            className="wd_filter-countaction"
          >
            <Link className="wd_filter-countaction clear-link inter-font  py-1">
              <div className="wd_filter-countaction mdFilter inter-font d-block d-md-block d-lg-block d-xl-none text-small text-center text-dark">
                {(activeTab === "Customers" &&
                  finalProducts &&
                  finalProducts.length) ||
                  (activeTab === "Vendor" && finalBwv && finalBwv.length) ||
                  "0"}{" "}
                results
              </div>
              <p
                style={{
                  margin: 0,
                  fontSize: "13px",
                  fontWeight: "bold",
                  color: "red",
                  cursor: "pointer",
                }}
              >
                Clear Results
              </p>
            </Link>
          </div>
          <div
            style={{
              //backgroundColor: "#FCEFEF",
              borderRadius: "10px",
              padding: "5px",
              cursor: "pointer",
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Dropdown isOpen={dropdownAds} toggle={toggle}>
              <DropdownToggle
                className="wd_filter-countaction inter-font "
                style={{
                  margin: 0,
                  fontSize: "small",
                  fontWeight: "bold",
                  color: "#000",
                  backgroundColor: "#FCEFEF",
                  cursor: "pointer",
                  padding: "9px 18px",
                  borderRadius: "10px",
                  borderColor: "white",
                }}
                onClick={() => setShowGurudwaraList(!showGurudwaraList)}
              >
                Latest Ads &nbsp; &nbsp;
                <FaCaretDown />
              </DropdownToggle>
              <DropdownMenu
                style={{
                  borderRadius: "10px",
                  padding: "10px",
                  zIndex: 1,
                  width: "215px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  marginLeft: "-31%",
                }}
                className="wd_filter-countaction wd_filter-countaction-hover inter-font"
              >
                <DropdownItem
                  onMouseEnter={(e) => (
                    (e.target.style.backgroundColor = "#5435C2"),
                    (e.target.style.color = "white")
                  )}
                  onMouseLeave={(e) => (
                    (e.target.style.backgroundColor = "initial"),
                    (e.target.style.color = "black")
                  )}
                  onClick={() => navigate("/dashboard")}
                  style={{
                    borderRadius: "0px 25px 0px 25px",
                    borderBottom: "1px solid rgb(224, 224, 224)",
                    fontSize: "14px",
                    padding: "8px 10px",
                  }}
                >
                  View all Gurdwaras
                </DropdownItem>
                {gurdwaras.map((gurdwara) => (
                  <DropdownItem
                    key={gurdwara.id}
                    onClick={() => handleGurudwaraSelect(gurdwara.name)}
                    onMouseEnter={(e) => (
                      (e.target.style.backgroundColor = "#5435C2"),
                      (e.target.style.color = "white")
                    )}
                    onMouseLeave={(e) => (
                      (e.target.style.backgroundColor = "initial"),
                      (e.target.style.color = "black")
                    )}
                    style={{
                      borderRadius: "0px 25px 0px 25px",
                      borderBottom: "1px solid rgb(224, 224, 224)",
                      fontSize: "14px",
                      padding: "8px 10px",
                    }}
                  >
                    {gurdwara.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>

        <Col className=" p-0 m-0" style={{ flexBasis: "33%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 8,
              // marginRight: "30px",
            }}
          >
            <div className="d-none d-md-block d-lg-block">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  // caret
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    // border: "1px solid #007BFF",
                  }}
                  className="wd_filter-btn"
                >
                  {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
                  <FaCaretDown />
                </DropdownToggle>
                <DropdownMenu style={{ color: "#fff" }}>
                  <DropdownItem onClick={() => handleSortBy("Newer to Older")}>
                    Newer to Older
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSortBy("Older to Newer")}>
                    Older to Newer
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      <div
        className="mb-4"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "7px",
        }}
      >
        {" "}
        <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
          <Col
            sm={8}
            style={{
              textAlign: "left",
              padding: "7px",
              color: "#000",
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            This site is FREE to use with purpose to serve the Sikh Community.
            {/* List your own Ads on the site by calling us (800) 245-3610. It's
            Free. */}
          </Col>
          <Col sm={4}>
            <button
              type="button"
              className="btn btn-danger m-0 px-5"
              style={{
                height: "100%",
                width: "100%",
                padding: "1rem", // Increase padding
                borderRadius: "20px", // Add border radius for rounded corners
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
              }}
              onClick={() => navigate("/needs-requests/request")}
            >
              Post Your Ad
            </button>
          </Col>
        </Row>
      </div>
      <LcaAndBwv scrollPopupVisible={scrollPopupVisible} />
    </div>
  );
};

export default RecentAds;
