import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { myOffers } from "../Data2";
import { useSelector } from "react-redux";

const LcaCardButtons = ({
  ad,
  openOfferModal,
  openMsgModal,
  questionsVisible,
  toggleQuestions,
  eventsVisible,
  toggleEvents,
}) => {
  const [offers, setOffers] = React.useState([]);
  const islogin = useSelector((state) => state.user.isLogin);
  const userdata = useSelector((state) => state.user);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const fetchMyOffers = async () => {
    const type = {
      Type: "provider",
    };
    let id = user.UserID;
    const response = await myOffers(id, type);
    console.log(response, "response");
    setOffers(response);
  };

  useEffect(() => {
    fetchMyOffers();
  }, [ad]);

  // Check if the ad.Id is included in the offers array
  const gotCustomer = offers.some((offer) => offer.Id === ad.Id);
  console.log(gotCustomer, "gotCustomer");
  return (
    <>
      {ad.engagedCouple === 1 ? (
        <div className="w-100 text-center engagedCouple">
          {gotCustomer ? (
            <>
              <a
                href={`tel: 8002453610`}
                className="btn btn-success btn-sm mb-2 w-100"
              >
                Call Now
              </a>
              <Button
                color="primary"
                size="sm"
                className="w-100"
                onClick={(event) => openMsgModal(event, ad)}
              >
                Send Message
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              size="sm"
              className="w-100 mb-2"
              onClick={(event) => openOfferModal(event, ad)}
            >
              Get Customer!
            </Button>
          )}
          <Button
            color={questionsVisible[ad.Id] ? "secondary" : "warning"}
            size="sm"
            className="w-100 mb-2"
            onClick={(event) => toggleQuestions(event, ad)}
          >
            {questionsVisible[ad.Id] ? "Hide Q&A" : "Show Q&A"}
          </Button>
          <Button
            color={eventsVisible[ad.Id] ? "secondary" : "success"}
            size="sm"
            className="w-100"
            onClick={(event) => toggleEvents(event, ad)}
          >
            {eventsVisible[ad.Id] ? "Hide Event Details" : "Show Event Details"}
          </Button>
        </div>
      ) : (
        <div className="w-100 text-center need">
          <a
            href={`tel: 8002453610`}
            className="btn btn-success btn-sm mb-2 w-100"
          >
            Call: (800) 245-3610 <br />
            <span>Extension # {ad.dialExtension}</span>
          </a>
          <Button
            color="primary"
            size="sm"
            className="w-100"
            onClick={(event) => openMsgModal(event, ad)}
          >
            Send Message
          </Button>
        </div>
      )}
    </>
  );
};

export default LcaCardButtons;
