import React, { useEffect, useState } from "react";
import NeedCategories from "./NeedCategories";
import { Row, Form, Col, FormGroup, Label, Input, Button } from "reactstrap";
import NeedPhotos from "./NeedPhotos";
import { useDispatch, useSelector } from "react-redux";
import { fetchGurdwaras } from "../../../components/Header/Data";
import CustmorAvailability from "./CustmorAvailability";
import { GetQuestionsById } from "../../../components/Header/Data2";
import NeedQuestions from "./NeedQuestions";
import NewlyEngagedForm from "../settingsComponents/NewlyEngagedForm";
import WhomToNotify from "./WhomToNotify";
import {
  setAlternativePhoneOne,
  setAlternativePhoneTwo,
  setDescription,
  setDialExtension,
  setPhone,
  setTerms,
  setTitle,
  setZipcode,
} from "../../../redux/slices/RequestFormSlice";

const UpcomingEvent = ({
  categories,
  subCategories1,
  subCategories,
  setSubCategories,
  editMode,
  handleFormSubmit,
  selectedCat,
  setSelectedCat,
  isLoading,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [gurdwaras, setGurdwaras] = useState([]);
  const formData = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  // const [selectedCat, setSelectedCat] = useState(null);
  const [questions, setQuestions] = useState([]);

  const fetchData = async () => {
    const gurudwaras = await fetchGurdwaras();
    setGurdwaras(gurudwaras);
  };
  useEffect(() => {
    const fetchQuestions = async () => {
      if (selectedCat) {
        const quest = await GetQuestionsById(selectedCat);
        console.log(quest);
        setQuestions(quest);
      }
    };
    fetchQuestions();
  }, [selectedCat]);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Form>
      <Col className="request-form p-4 mt-3">
        <p className="text-center">Upcoming Wedding/Event</p>
        <NeedCategories
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          setSelectedCat={setSelectedCat}
        />
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Enter Ad Title <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="Title"
                placeholder="Ex: Seeking an event Decorator great with Flowers"
                value={formData.Title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <CustmorAvailability />
        </Row>
        <Row>
          <div style={{ marginTop: "-3%", marginBottom: "2%" }}>
            <NewlyEngagedForm userDetails={formData} />
          </div>
        </Row>
        <Row>
          <FormGroup>
            <Label>AD Description. {/*Mention Contact Phone Number!*/}</Label>
            <Input
              type="textarea"
              name="Description"
              placeholder="Expectations & Requirements from the vendors.."
              value={formData.Description}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  dispatch(setDescription(e.target.value));
                }
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Enter Zip code
                <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="Zipcode"
                placeholder="Enter Zip code where Service Vendor should arrive"
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidZipCode = /^\d{0,5}$/.test(value); // Ensure up to 5 digits

                  if (isValidZipCode) {
                    dispatch(setZipcode(value));
                  }
                }}
                value={formData.Zipcode}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col-lg-3 phone">
            <FormGroup>
              <Label>
                Mobile Number <b className="text-danger">*</b>
              </Label>
              <Input
                type="number"
                name="Phone"
                value={formData.Phone}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setPhone(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 alterPhone-1">
            <FormGroup>
              <Label> Mobile Number (family member)</Label>
              <Input
                type="number"
                name="alternativePhoneOne"
                value={formData.alternativePhoneOne}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setAlternativePhoneOne(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 alterPhone-2">
            <FormGroup>
              <Label> Mobile Number (family member)</Label>
              <Input
                type="number"
                name="alternativePhoneTwo"
                value={formData.alternativePhoneTwo}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setAlternativePhoneTwo(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 diel-ext">
            {isAdmin && (
              <FormGroup>
                <Label>
                  Dial Extension <b className="text-danger">*</b>
                </Label>
                <Input
                  type="text"
                  name="dialExtension"
                  value={formData.dialExtension}
                  onChange={(e) => {
                    const { value } = e.target;
                    const isValid = /^\d{0,5}$/.test(value);
                    if (isValid) {
                      dispatch(setDialExtension(value));
                    }
                  }}
                  disabled={user?.RoleId === 1 ? false : true}
                />
              </FormGroup>
            )}
          </div>
        </Row>
        <NeedPhotos />
      </Col>
      <Col className="request-form p-4  mt-5">
        <NeedQuestions catQuestions={questions} editMode={editMode} />
      </Col>
      {isAdmin && (
        <Col className="request-form p-4  mt-3">
          <h5 className="pb-3">Whom To Be Notified</h5>
          <WhomToNotify />
        </Col>
      )}
      <Col className="request-form p-4  mt-3">
        <h5 className="pb-3">Terms</h5>
        <h6 className="fst-italic pb-3 small">
          You'll have several vendors provide you an offer,we want to have all
          parties feel heard so please respond to their inbox messages always.
        </h6>
        <div class="mb-3 form-check">
          <FormGroup>
            <Input
              type="checkbox"
              name="Terms"
              checked={formData.Terms === 1}
              onChange={(e) => {
                dispatch(setTerms(e.target.checked ? 1 : 0));
              }}
              class="form-check-input"
              id="exampleCheck1"
            />
            <Label class="form-check-label ps-2" for="exampleCheck1" required>
              I understand and Accept Terms
            </Label>
          </FormGroup>
        </div>
      </Col>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          padding: "10px",
        }}
      >
        <Button
          type="submit"
          className="btn btn-danger"
          disabled={isLoading}
          onClick={handleFormSubmit}
        >
          {Object.keys(editMode).length > 0
            ? "Update Request"
            : "Submit Request"}
        </Button>
      </div>
    </Form>
  );
};

export default UpcomingEvent;
